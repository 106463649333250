import React, { createContext, useContext, useState, ReactNode } from 'react';

type StatusType = 'success' | 'warning' | 'error';

interface StatusContextProps {
    openStatus: (status: StatusType, description: string) => void;
    closeStatus: () => void;
    status: StatusType | null;
    description: string;
    show: boolean;
}

const StatusContext = createContext<StatusContextProps | undefined>(undefined);

export const useStatusContext = () => {
    const context = useContext(StatusContext);
    if (!context) {
        throw new Error('useStatusContext must be used within a StatusProvider');
    }
    return context;
};

interface StatusProviderProps {
    children: ReactNode;
}

export const StatusProvider = ({ children }: StatusProviderProps) => {
    const [status, setStatus] = useState<StatusType | null>(null);
    const [description, setDescription] = useState<string>('');
    const [show, setShow] = useState<boolean>(false);

    const openStatus = (status: StatusType, description: string) => {
        setStatus(status);
        setDescription(description);
        setShow(true);

        setTimeout(() => {
            setShow(false);
        }, 3000);
    };

    const closeStatus = () => {
        setShow(false);
    };

    return (
        <StatusContext.Provider value={{ openStatus, closeStatus, status, description, show }}>
            {children}
        </StatusContext.Provider>
    );
};
