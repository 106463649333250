import React, { useEffect, useRef, useState } from "react";
import s from './Select.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import {FormikErrors} from "formik";

type SelectProps = {
    values: any[] | any;
    change?: (value: string) => void;
    refSelect?: React.RefObject<HTMLDivElement>;
    defaultValue?: string;
    modules?: boolean;
    label?: string;
    setModulesId?: React.Dispatch<React.SetStateAction<number[]>>;
    modulesId?: number[];
    disable?: boolean;
    wantId?: boolean;
    setFieldValue?: (field: string, value: any, shouldValidate?: (boolean | undefined)) => Promise<void | FormikErrors<{
        marker: string,
        name: string,
        type: string }
    >>
};

export const Select = ({
                           values,
                           change,
                           refSelect,
                           defaultValue,
                           modules,
                           label,
                           setModulesId,
                           modulesId = [],
                           disable,
                           setFieldValue,
                           wantId
                       }: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const selectRef = useRef<HTMLDivElement>(null);
    const toggleDropdown = (): void => setIsOpen(!isOpen);

    const selectOption = (option: string, element?: any): void => {
        setSelectedOption(option);
        setFieldValue && setFieldValue("type", option)
        setIsOpen(false);
        change?.(wantId ? element : option);
    };

    const handleClickOutside = (event: MouseEvent): void => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleModulesChange = (id: any, checked: boolean) => {
        // @ts-ignore
        setModulesId?.((prevModulesId: string[]) => {
            const updatedModulesId = checked
                ? [...prevModulesId, id]
                : prevModulesId?.filter(moduleId => +moduleId !== +id);
            setSelectedOption(`${updatedModulesId.length} modules`);
            return updatedModulesId;
        });
    };

    const renderOptions = () => {
        if (modules) {
            return values?.modules?.map((v: any, index: number) => (
                <li key={index} className={s.option}>
                    {v.name}
                    <input
                        type="checkbox"
                        checked={modulesId?.includes(v.id)}
                        onChange={(e) => handleModulesChange(v.id, e.target.checked)}
                    />
                </li>
            ));
        } else {
            return values?.map((option: any, index: number) => (
                <li
                    key={index}
                    onClick={() => selectOption(option.name ?? option?.props?.value, option)}
                    className={s.option}
                >
                    {option?.name ?? option?.props?.value}
                    {selectedOption === (option?.name ?? option?.props?.value) && <Check />}
                </li>
            ));
        }
    };

    const handleRemoveModule = (title: string) => {
        setModulesId?.(prevModulesId => {
            const indexToRemove = values?.modules?.findIndex((v: any) => v.name === title);
            if (indexToRemove !== -1) {
                const idToRemove = values?.modules?.[indexToRemove].id;
                const updatedModulesId = prevModulesId.filter(moduleId => moduleId !== idToRemove);
                setSelectedOption(`${updatedModulesId.length} modules`);

                return updatedModulesId;
            }
            return prevModulesId;
        });
    };

    return (
        <>
            <div className={s.custom_select} ref={selectRef}>
                {label && <label>{label}</label>}
                <div
                    className={`${s.selected} ${isOpen && s.selected_active} ${!selectedOption && s.default} ${disable && s.disable}`}
                    onClick={disable ? undefined : toggleDropdown}
                    ref={refSelect}
                >
                    {selectedOption ?? defaultValue}
                    <ArrowTools/>
                </div>
                {isOpen && <ul className={s.options}>{renderOptions()}</ul>}

            </div>
            {modules && (
                <div className={s.radio_items}>
                    {values?.modules?.filter((v: any) => modulesId?.find(id => id === v.id))
                        .map((module: any, index: number) => (
                            <div key={index} className={s.item}>
                                <p>{module.name}</p>
                                <Close onClick={() => handleRemoveModule(module.name)} />
                            </div>
                        ))}
                </div>
            )}
        </>

    );
};
