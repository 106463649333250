import React, {useEffect, useState} from 'react';
import s from "./Pages.module.scss";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {pagesApi, useDeletePageMutation, useGetPageQuery, useGetPagesQuery} from "../../redux/apis/pagesApi";
import {useDispatch, useSelector} from "react-redux";
import {sectionApi, useDeleteSectionMutation} from "../../redux/apis/sectionApi";
import {useDeleteBlockMutation} from "../../redux/apis/blocksApi";

import edit_icon from 'assets/icons/edit_icon.svg';
import {useGetLangsQuery} from "../../redux/apis/langApi";
import {Preloader} from "components/Preloader/Preloader";
import {key} from "utils/getKey";
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more_vertical.svg";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {MenuMini} from "components/MenuMini/MenuMini";
import deleteIcon2 from 'assets/icons/delete_icon2.svg';
import {PagesModal} from "./componens/PagesModal/PagesModal";
import {SectionModal} from "./componens/SectionModal/SectionModal";
import {BlocksModal} from "./componens/BlocksModal/BlocksModal";
import questionMark from "assets/images/questionMark.webp";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useLocation} from "react-router-dom";
import {useStatusContext} from "components/StatusProvider";
import {useTranslation} from "react-i18next";
import {ModuleModal} from "./componens/ModuleModal/ModuleModal";
import {ViewModule} from "./componens/ViewModule/ViewModule";
import {EditModule} from "./componens/ViewModule/components/EditModule/EditModule";
import {AddElementToArray} from "./componens/ViewModule/components/AddElementToArray/AddElementToArray";

type PropsSectionModal = {
    state?: boolean;
    id?: any;
    el?: any;
}

export const Pages = () => {
    const pages = useGetPagesQuery({});
    const {data: pagesNew, refetch: refetchPages}  = useGetPagesQuery({});
    const [currentPage, setCurrentPage] = useState<number>();
    const { data: pageNew, refetch } = useGetPageQuery({ id: currentPage }, {refetchOnMountOrArgChange: true, skip: !currentPage});

    const [pageState, setPageState] = useState<any>(false);
    const location = useLocation();
    const locationProps = location.state;

    let sections_view = [];
    const dispatch = useDispatch();

    const [pagesModal, setPagesModal] = useState<PropsSectionModal>({state: false, id: false});
    const [sectionModal, setSectionModal] = useState<PropsSectionModal>({state: false, id: false, el: false});
    const [blocksModal, setBlocksModal] = useState<any>({state: false, section: false, block: false});
    const [langState, setLangState] = useState(false);

    const [deleteSection] = useDeleteSectionMutation();
    const [deleteBlock] = useDeleteBlockMutation();
    const [deletePage] = useDeletePageMutation();

    const lang = useGetLangsQuery({});
    const langs = useSelector((store: any) => store.main.langs);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [idMenu, setIdMenu] = useState<number>();
    const [name, setName] = useState<string>();
    const [modalElement, setModalElement] = useState<any>();
    const [showModalPage, setShowModalPage] = useState<boolean>(false);
    const [showModalSection, setShowModalSection] = useState<boolean>(false);
    const [showModalBlock, setShowModalBlock] = useState<boolean>(false);
    const { openStatus } = useStatusContext();
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const { t } = useTranslation();
    const [isAddModule, setIsAddModule] = useState<boolean>(false);
    const [moduleModal, setModuleModal] = useState<boolean>(false);
    const [moduleId, setModuleId] = useState<number>();
    const [idSection, setIdSection] = useState<number>(0);
    const [hiddenSections, setHiddenSections] = useState<number[]>([]);
    const [editModule, setEditModule] = useState<any>({show: false, element: false});
    const [elementOfArray, setElementOfArray] = useState<any>({ show: false, element: false });

    useEffect(() => {
        if (pagesNew) {
            setHiddenSections(pagesNew?.data?.map((el: any) => el?.id) || []);
            setCurrentPage(pagesNew?.data?.[0]?.id);
        }

    }, [pagesNew]);

    useEffect(() => {
        if (locationProps) {
            setSectionModal({state: true, el: locationProps.section})
        }
    }, [locationProps]);

    const handleMenu = (id: number) => {
        setIdMenu(id);
        setOpenMenu(!openMenu);
    }

    if (lang?.isFetching  || pages?.isFetching  || pageNew?.isFetching) return <Preloader />

    if (lang?.data && pages?.data && pageNew) {

        if (!pages?.data?.data?.length) return null;

        if (!pageState) {
            if(pages.data){
                setPageState(pages.data.data[0]);
            }
            return null;
        }

        if (!langState) {
            setLangState(lang.data.data[0].key);
            return null;
        }

        const toggleHide = (id: number) => {
            setHiddenSections((prev) => {
                if (prev.includes(id)) {
                    return prev.filter((sectionId) => sectionId !== id);
                } else {
                    return [...prev, id];
                }
            });
        };

        sections_view = pageNew?.content?.map((section: any) => {
            if (!section) return null;
            const section_items = [
                {icon: edit_icon, text: t("general.edit"), callback: () => toggleSectionModal(section)},
                {icon: deleteIcon2, text: t("general.delete"), callback: () => handleDelSection(section)},
            ]

            return (
                <div key={section.id} className={`${s.section}`}>
                    <div className={`${s.section_wrap} ${hiddenSections.includes(section.id) ? s.hide : ''}`}>
                        <div className={s.header}>
                            <p>{`${t("pages.section")} `}<span>{`${section.name}`}</span></p>
                            <div>
                                <ArrowTools className={s.arrow_tools} onClick={() => toggleHide(section.id)}/>
                                <MoreVertical className={s.more_content} onClick={() => handleMenu(section.id)}/>
                                <MenuMini
                                    items={section_items}
                                    open={openMenu && idMenu === section.id}
                                    close={setOpenMenu}
                                    section
                                />
                            </div>
                        </div>
                        {
                            section?.content?.map((block: any) => {
                                const block_items = [
                                    {
                                        icon: edit_icon,
                                        text: t("general.edit"),
                                        callback: () => toggleBlockModal(section, block)
                                    },
                                    {icon: deleteIcon2, text: t("general.delete"), callback: () => handleDelBlock(block)},
                                ]

                                if (block?.isModule) {
                                    return <ViewModule
                                                object={block}
                                                sectionId={section.id}
                                                refetchGetSection={refetch}
                                                setEditModule={setEditModule}
                                                setIsAddModule={setIsAddModule}
                                                setModuleId={setModuleId}
                                                setIdSection={setIdSection}
                                                setElementOfArray={setElementOfArray}
                                        />
                                }

                                if (block.type === "text" || block.type === "link") {
                                    return (
                                        <div key={block.id} className={`${s.block} anchor-${block.id}`}>
                                            <header>
                                                <div className={s.info_block}>
                                                    <div className={s.header_item}>
                                                        <p className={s.title}>{`${t("general.type")}: `}</p>
                                                        <p className={s.value}>{block.type}</p>
                                                    </div>

                                                    <div className={s.header_item}>
                                                        <p className={s.title}>{`ID: `}</p>
                                                        <p className={s.value}>{block.id}</p>
                                                    </div>

                                                    {block.link ?
                                                        <div className={s.header_item}>
                                                            <p className={s.title_link}>{`${t("pages.link")}: `}</p>
                                                            <p className={s.value}>{block.link}</p>
                                                        </div> : null
                                                    }
                                                    {block.marker ?
                                                        <div className={s.header_item}>
                                                            <p className={s.title}>{`${t("pages.marker")}: `}</p>
                                                            <p className={s.value}>{block.marker}</p>
                                                        </div> : null
                                                    }
                                                </div>
                                            </header>
                                            <div className={s.content}>
                                                <div className={`${s.text_block}`}>
                                                    <p dangerouslySetInnerHTML={{__html: block?.content?.filter((value: any) => value?.lang_key === String(langState))?.[0]?.value || ''}}></p>
                                                </div>
                                                <div className={s.lang_block}>
                                                    {
                                                        langs?.map((el: any) => {
                                                            return (
                                                                <div
                                                                    key={key()}
                                                                    className={`${s.lang_item} ${(el.key === String(langState)) && s.lang_item_active}` }
                                                                    onClick={() => setLangState(el.key)}
                                                                >
                                                                    <div className={s.lang}>{el.key}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={s.tools}>
                                                <MoreVertical
                                                    className={s.more_content}
                                                    onClick={() => handleMenu(block.id)}
                                                />
                                                <MenuMini
                                                    items={block_items}
                                                    open={openMenu && idMenu === block.id}
                                                    close={setOpenMenu}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                else if (block.type === 'image' || block.type === 'video') {
                                    const images_items = [
                                        {
                                            icon: edit_icon,
                                            text: t("general.edit"),
                                            callback: () => toggleBlockModal(section, block, block?.content)
                                        },
                                        {icon: deleteIcon2, text: t("general.delete"), callback: () => delBlock(block)},
                                    ]

                                    return (
                                        <div key={block.id} className={`${s.block} anchor-${block?.id}`}>
                                            <header>
                                                <div className={s.info_block}>
                                                    <div className={s.header_item}><span
                                                        className={s.title}>{`${t("general.type")}: `}</span><span
                                                        className={s.value}>{block.type}</span></div>
                                                    <div className={s.header_item}><span
                                                        className={s.title}>{`ID: `}</span><span
                                                        className={s.value}>{block?.id}</span></div>
                                                    {block.type === 'image' ? <div className={s.header_item}><span
                                                        className={s.title}>{`alt: `}</span><span
                                                        className={s.value}>{block?.alt}</span></div> : null}
                                                    {block.marker ? <div className={s.header_item}><span
                                                        className={s.title}>{`Marker: `}</span><span
                                                        className={s.value}>{block?.marker}</span></div> : null}
                                                </div>
                                            </header>

                                            <div className={s.content}>
                                                <div className={s.images_content}>
                                                    {langs?.filter((ln: any) => block?.content?.find((el: any) => el.lang_key === ln.key)?.link)?.map((ln: any, index: number) => (
                                                        <div className={s.image_item_wrapper} key={index}>
                                                            <div
                                                                className={`${s.header} ${block?.content?.find((el: any) => el.lang_key === ln.key)?.default_image ? s.color : ""}`}>
                                                                <span className={s.language}>{ln.key}</span>
                                                                {
                                                                    block?.content?.find((el: any) => el.lang_key === ln.key)?.default_image &&
                                                                    <span>| Default</span>
                                                                }
                                                            </div>
                                                            {
                                                                block.type === 'image' ?
                                                                    <div className={s.image_wrapper} style={{
                                                                        backgroundImage: `url(${API_ADDRESS}${block?.content?.find((el: any) => el.lang_key === ln.key)?.link})`
                                                                    }}></div>
                                                                    :
                                                                    <div className={s.image_wrapper} >
                                                                        <video
                                                                            playsInline
                                                                            src={API_ADDRESS + block?.content?.find((el: any) => el.lang_key === ln.key)?.link}
                                                                            controls={true}
                                                                            style={{width: '100%', height: '100%'}}
                                                                        />
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className={s.tools}>
                                                <MoreVertical
                                                    className={s.more_content}
                                                    onClick={() => handleMenu(block.id)}
                                                />
                                                <MenuMini
                                                    items={images_items}
                                                    open={openMenu && idMenu === block.id}
                                                    close={setOpenMenu}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }

                        <div className={s.add_buttons} >
                            <PrimaryButton
                                type="button"
                                text={t("pages.addABlock")}
                                onClick={() => toggleBlockModal(section)}
                                icon={<Plus/>}
                                className={s.button}
                            />
                            <PrimaryButton
                                type="button"
                                text={t("general.addModule")}
                                onClick={() => {
                                    setModuleModal(true);
                                    setIdSection(section.id);
                                }}
                                icon={<Plus/>}
                                className={s.button}
                            />
                        </div>

                    </div>
                </div>
            )
        })
    }

    function toggleBlockModal(el: any, block: any = false, file: any = undefined) {
        const values = {
            'type': block?.type,
            'link': block?.link ? block?.link : false,
            'pseudo': block.text || file?.[0]?.pseudo,
            'block_id': block.id,
            'file': file,
            'terms_block': block?.content,
            'marker': block.marker
        }
        setBlocksModal((prev: any) => {
            return {state: !prev.state, section: el, values: values, lang_data: lang.data.data}
        })
    }

    function toggleSectionModal(el: any = false) {
        setSectionModal({state: true, el: el})
    }

    async function delPage(page: any) {
        const data = page ? page : pageState;

        const result: any = await deletePage(data.id);
        if (result.error?.data.message === 1000) {
            openStatus('warning', "status.unfortunately");
            refetch();
        } else if (result.data.message === 33) {
            openStatus('success', "status.operationSuccessful");
            // @ts-ignore
            dispatch(pagesApi.util.resetApiState(['Pages']));
            setPageState(false);
        }
    }

    async function delSection(el: any) {
        const result: any = await deleteSection({section: el.id, page_id: pageState.id});
        if (result.error?.data.message === 1000) {
            openStatus('warning', "status.unfortunately");
            refetch();
        } else if (result.data.message === 37) {
            openStatus('success', "status.operationSuccessful");
            // @ts-ignore
            dispatch(sectionApi.util.resetApiState(['Pages']))
            // @ts-ignore
            dispatch(sectionApi.util.resetApiState(['Section']));
        }
    }

    const delBlock = async (block: any) => {
        const res: any = await deleteBlock([block.id]);
        if (res.error?.data.message === 1000) {
            openStatus('warning', "status.unfortunately");
            refetch();
        } else if (res.data.message === "blocks_successfully_deleted") {
            openStatus('success', "status.operationSuccessful");
            dispatch(sectionApi.util.invalidateTags(['Section']));
        }
    };

    const handleDelPage = (page: any) => {
        setName(page ? page.name : pageState);
        setModalElement(page);
        setShowModalPage(true);
        refetch();
    }

    const handleDelSection = (el: any) => {
        setName(el.name);
        setModalElement(el);
        setShowModalSection(true);
        refetch();
    }

    const handleDelBlock = (block: any) => {
        setName(block.marker);
        setModalElement(block);
        setShowModalBlock(true);
        refetch();
    }

    const pages_view = pages?.data?.data?.map((el: any) => {
        const menu_items = [
            {icon: edit_icon, text: t("general.edit"), callback: () => setPagesModal({state: true, el: el, id: el.id})},
            {icon: deleteIcon2, text: t("general.delete"), callback: () => handleDelPage(el)},
        ]
        return (
            <div
                key={el.id}
                className={`${s.page_item} ${pageState?.id === el.id ? s.active : ''}`}
                onClick={() => {
                    setPageState(pages?.data?.data?.find((page: any) => page?.id === el?.id))
                    setCurrentPage(el?.id)
                }}
            >
                <p>
                    {el?.name}
                </p>
                <MoreVertical className={s.more_page} onClick={() => handleMenu(el.id)}/>
                <MenuMini
                    items={menu_items}
                    open={openMenu && idMenu === el.id}
                    close={setOpenMenu}
                    page
                />
            </div>
        )
    })

    if (elementOfArray?.show) {
        return (
            <AddElementToArray
                setElementOfArray={setElementOfArray}
                elementOfArray={elementOfArray}
                moduleId={moduleId}
                refetchGetSection={refetch}
            />
        )
    }

    if (isAddModule) {
        return (
            <ModuleModal
                close={setIsAddModule}
                id={moduleId}
                idSection={idSection}
                setIsAddModule={setIsAddModule}
                refetchGetSection={refetch}
            />
        )
    }

    if (pagesModal.state) {
        return (
            <PagesModal
                close={setPagesModal}
                el={pagesModal.el}
                pageState={pageState}
                refetchGetPages={refetchPages}
            />
        )
    }

    if (sectionModal.state) {
        return (
            <SectionModal
                close={setSectionModal}
                el={sectionModal.el}
                pageState={pageState}
                refetchGetSection={refetch}
            />
        )
    }

    if (blocksModal.state) {
        return (
            <BlocksModal
                close={setBlocksModal}
                section={blocksModal.section}
                values={blocksModal.values}
                lang_data={blocksModal.lang_data}
                refetchGetSection={refetch}
            />
        )
    }

    if (editModule?.show) {
        return (
            <EditModule
                element={editModule?.element}
                setEditModule={setEditModule}
                refetchGetSection={refetch}
            />
        )
    }

    const description = (
        <>
            {t("general.wantToRemove")} <span>{name}</span> ? <br />
            {t("general.itsContents")}
        </>
    );

    const addModulePage = (module: any) => {
        setModuleId(module?.id);
        setIsAddModule(true);
    }

    return (
        <div className={s.pages}>
            <HeaderSection
                title={t("pages.pagesName")}
                buttonText={t("pages.addANewPage")}
                icon={<Plus />}
                onClick={() => setPagesModal({state: true, el: false})}
            />

            {pagesModal.state ?
                <PagesModal
                    close={setPagesModal}
                    el={pagesModal.el}
                    pageState = {pageState}
                    refetchGetPages={refetchPages}
                /> :

                <div className={s.contents}>
                    <div className={s.top}>
                        {pages_view}
                    </div>

                    <div className={s.left}>
                        {sections_view}
                        {pagesNew?.data?.length !== 0 && <PrimaryButton
                            type="button"
                            text={t("pages.addANewSection")}
                            onClick={() => toggleSectionModal()}
                            icon={<Plus/>}
                            className={s.button}
                        />}
                    </div>
                </div>
            }

            <ModalGeneral
                showModal={showModalPage}
                setShowModal={setShowModalPage}
                image={questionMark}
                buttonRight={() => delPage(modalElement)}
                title={t("pages.deleteThePage")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                description={description}
            />
            <ModalGeneral
                showModal={showModalSection}
                setShowModal={setShowModalSection}
                image={questionMark}
                buttonRight={() => delSection(modalElement)}
                title={t("pages.deleteASection")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                description={description}
            />
            <ModalGeneral
                showModal={showModalBlock}
                setShowModal={setShowModalBlock}
                image={questionMark}
                buttonRight={() => delBlock(modalElement)}
                title={t("pages.removeTheBlock")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                description={description}
            />
            <ModalGeneral
                showModal={moduleModal}
                setShowModal={setModuleModal}
                buttonRight={addModulePage}
                title={t("general.addModule")}
                textRightButton={t("general.add")}
                textLeftButton={t("general.cancel")}
                addModule
            />
        </div>
    )
}