import {Select} from "../../../Select/Select";
import {useGetModulesQuery} from "../../../../redux/apis/modulesApi";
import React from "react";

type AddModuleProps = {
    setModule: React.Dispatch<any>;
}

export const AddModule = ({ setModule }: AddModuleProps) => {
    const { data: modules } = useGetModulesQuery({});

    const chooseModule = (value: any) => {
        setModule(value);
    }

    return (
        <Select
            values={modules.modules}
            defaultValue={"Виберіть значення"}
            change={chooseModule}
            wantId
        />
    )
}