import i18n, {ResourceLanguage} from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enLanguage from './locales/en/translation.json';
import ruLanguage from './locales/ru/translation.json';
import {Locale} from "./utils/enums";


const resources: { [key in Locale]: ResourceLanguage } = {
    [Locale.EN]: {
        translation: enLanguage
    },
    [Locale.RU]: {
        translation: ruLanguage
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        supportedLngs: ['en', 'ru'],
        fallbackLng: 'en',
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
    })
    .then(() => {
        console.log('i18n initialized', i18n);
    });

export default i18n;
