import { configureStore } from "@reduxjs/toolkit";
import { blocksApi } from "./apis/blocksApi";
import { langApi } from "./apis/langApi";
import { mediaApi } from "./apis/mediaApi";
import { pagesApi } from "./apis/pagesApi";
import { postsApi } from "./apis/postsApi";
import { sectionApi } from "./apis/sectionApi";
import { termsApi } from "./apis/termsApi";
import { userApi } from "./apis/userApi";
import mainSlice from "./slices/mainSlice";
import { modulesApi } from "./apis/modulesApi";

export const store = configureStore({
    reducer : {
        main: mainSlice,
        [userApi.reducerPath]: userApi.reducer,
        [termsApi.reducerPath]: termsApi.reducer,
        [langApi.reducerPath]: langApi.reducer,
        [sectionApi.reducerPath]: sectionApi.reducer,
        [blocksApi.reducerPath]: blocksApi.reducer,
        [mediaApi.reducerPath]: mediaApi.reducer,
        [pagesApi.reducerPath]: pagesApi.reducer,
        [postsApi.reducerPath]: postsApi.reducer,
        [modulesApi.reducerPath]: modulesApi.reducer,
    },
    middleware : 
        (getDefaultMiddleware) => getDefaultMiddleware().concat(
            userApi.middleware, 
            termsApi.middleware,
            langApi.middleware,
            sectionApi.middleware,
            blocksApi.middleware,
            mediaApi.middleware,
            pagesApi.middleware,
            postsApi.middleware,
            modulesApi.middleware
            ),
           
        
    
    
});
