import { HeaderSection } from "components/HeaderSection/HeaderSection";
import s from './Module.module.scss';
import {ReactComponent as Plus} from "assets/icons/plus.svg";
import {ReactComponent as ArrowTools} from "assets/icons/arrow_tools.svg";
import {ReactComponent as MoreVertical} from "assets/icons/more_vertical.svg";
import {ReactComponent as EditIcon} from "assets/icons/edit_icon.svg";
import {ReactComponent as Trash} from "assets/icons/trash.svg";
import questionMark from "assets/images/questionMark.webp";
import React, {useEffect, useRef, useState} from "react";
import {ModalGeneral} from "components/Modals/ModalGeneral";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import { useGetModuleQuery, useUpdateTypeModuleMutation} from "../../redux/apis/modulesApi";
import {addObjectToValues, removeElementByName, updateObjectById} from "utils/moduleType";
import {useStatusContext} from "../../components/StatusProvider";

type ModuleProps = {
    id?: number;
    title?: string;
    close?: any;
}

export interface ValueItem {
    id?: number;
    name: string;
    type: string;
    marker?: string;
    values?: any[];
}


export interface ModuleValuesItemProps {
    name: string;
    type: string;
    value: ValueItem;
}

export interface RenderModuleValuesProps {
    values: any[];
    setting: any;
    valuesForm?: any;
}

export const Module = ({ id, title }: ModuleProps) => {
    const { data: module, refetch } = useGetModuleQuery({ id }, { skip: !id, refetchOnMountOrArgChange: true });
    const [object, setObject] = useState<any[]>([]);
    const [showMenu, setShowMenu] = useState<number[]>([]);
    const [hidden, setHidden] = useState<number[]>([]);
    const elementRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [findKey, setFindKey] = useState<number>(0);
    const [editElement, setEditElement] = useState<any>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const [ updateTypeModule ] = useUpdateTypeModuleMutation();
    const [currentMarkers, setCurrentMarkers] = useState<string[]>([]);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const { openStatus } = useStatusContext();

    useEffect(() => {
        if (module) {
            setObject([JSON.parse(module?.module?.type)]);
        } else {
            setObject([{
                type: "object",
                name: "Test",
                marker: "test",
                values: {}
            }]);
        }
    }, [id, module]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
                setShowMenu([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAdd = (element?: any) => {
        setOpenModal(true);
        setEditElement(undefined);
        element && setType(element?.type);
    }

    const handleEdit = (element: any) => {
        setOpenModalEdit(true);
        setEditElement(element);
    }

    const handleDelete = (element: any) => {
        setShowDeleteModal(true);
        setEditElement(element);
    }

    const menu = (allowAdd: boolean, element: any) => {
        const markers = Array.isArray(element?.values)
            ? element?.values.map((el: any) => el?.marker)
            : element?.values && typeof element?.values === 'object'
                ? Object.values(element?.values).map((el: any) => el.marker)
                : [];

        return (
            <div className={s.menu} ref={elementRef}>
                {(allowAdd && (element.type === "array" ? element?.values?.length === 0 : true)) &&
                    <div className={s.menu_item} onClick={() => {handleAdd(element); setCurrentMarkers(markers)}}>{t("general.add")} <Plus /></div>
                }
                <div className={s.menu_item} onClick={() => {handleEdit(element); setCurrentMarkers(markers)}}>{t("general.edit")} <EditIcon /></div>
                <div className={s.menu_item} onClick={() => {handleDelete(element)}}>{t("general.delete")} <Trash /></div>
            </div>
        )
    }

    const setting = (allowAdd: boolean, element: any) => {
        if (element) {
            return (
                <div className={s.setting}>
                    {(element?.values && Object.keys(element.values).length > 0) && <ArrowTools className={s.setting_arrow} onClick={() => handleArrowClick(element?.id)}  />}
                    <MoreVertical className={s.setting_tools} onClick={() => handleShowMenu(element?.id)} />
                    {showMenu?.includes(element.id) && menu(allowAdd, element)}
                </div>
            )
        }
    }

    const handleArrowClick = (id: number) => {
        setHidden(prevState =>
            prevState.includes(id)
                ? prevState.filter(item => item !== id)
                : [...prevState, id]
        );
    };

    const handleShowMenu= (id: number) => {
        setShowMenu(prevState => {
            const updatedId = prevState.includes(id)
                ? prevState.filter(item => item !== id)
                : [...prevState, id];

            setFindKey(updatedId[0]);
            return updatedId;
        });
    };

    const addInfo = (values: any) => {
        if (object?.length === 0 ) {
            addObjectToValues({}, findKey, values);
        } else {
            object?.map(obj => {
                addObjectToValues(obj, findKey, values);
            })
        }

        setOpenModal(false);
        setIsUpdated(true);
    }

    const editInfo = (values: object) => {
        updateObjectById(findKey, values, setObject);
        setOpenModalEdit(false);
        setIsUpdated(true);
    }

    const deleteInfo = () => {
        removeElementByName(findKey, setObject)
        setIsUpdated(true);
    }

    const ModuleValuesItem = ({ name, type, value }: ModuleValuesItemProps) => {
        return (
            <div className={s.container_third}>
                <div className={`${s.item} ${hidden.includes(value?.id as number) && s.hide_1}`}>
                    <div className={s.item_name}>
                        <div className={s.main_info}>
                            <div className={s.data}>
                                <p>{t("pagesModal.name")}:</p>
                                <span>{name}</span>
                            </div>
                            <div className={s.data}>
                                <p>{t("pages.marker")}:</p>
                                <span>{value?.marker}</span>
                            </div>
                        </div>
                        <span className={classType(type)}>{type}</span>
                        {setting((type === "object" || type === "array"), value)}
                    </div>
                </div>
            </div>
        );
    };

    const RenderModuleValues = ({values, setting, valuesForm}: RenderModuleValuesProps) => (
        <>
            {Array.isArray(values) ? (
                values?.map((value, index) => (
                    value?.values ? (
                        <div className={s.container_first}>
                            <div className={`${s.module_values_item} ${hidden?.includes(value?.id) && s.hide_1}`} key={index}>
                                <div className={s.item_name}>
                                    <div className={s.main_info}>
                                        <div className={s.data}>
                                            <p>{t("pagesModal.name")}:</p>
                                            <span>{value?.name}</span>
                                        </div>
                                        <div className={s.data}>
                                        <p>{t("pages.marker")}:</p>
                                            <span>{value?.marker}</span>
                                        </div>
                                    </div>
                                    <span className={classType(value?.type)}>{value.type}</span>
                                    {setting((value.type === "object" || value.type === "array"), value)}
                                </div>
                                <RenderModuleValues
                                    values={value.values}
                                    setting={setting}
                                />
                            </div>
                        </div>
                    ) : (
                        <ModuleValuesItem
                            key={index}
                            name={value?.name}
                            type={value?.type}
                            value={value}
                        />
                    )
                ))
            ) : typeof values === 'object' && values !== null ? (
                Object.values(values).map((value: any, index: number) => (
                    value?.values ? (
                        <div className={s.container_second}>
                            <div className={`${s.module_values_item} ${hidden?.includes(value?.id) && s.hide_1}`}
                                 key={index}>
                                <div className={s.item_name}>
                                    <div className={s.main_info}>
                                        <div className={s.data}>
                                            <p>{t("pagesModal.name")}:</p>
                                            <span>{value?.name}</span>
                                        </div>
                                        <div className={s.data}>
                                            <p>{t("pages.marker")}:</p>
                                            <span>{value?.marker}</span>
                                        </div>
                                    </div>
                                    <span className={classType(value?.type)}>{value.type}</span>
                                    {setting((value.type === "object" || value.type === "array"), value)}
                                </div>
                                <RenderModuleValues
                                    values={value.values}
                                    setting={setting}
                                    valuesForm={valuesForm}
                                />
                            </div>
                        </div>

                    ) : (
                        <ModuleValuesItem
                            key={index}
                            name={value.name}
                            type={value.type}
                            value={value}
                        />
                    )
                ))
            ) : null}
        </>
    );

    const deletedElement =
        <>
            {t("general.areYouSure")} <span className={s.deletedElement}>{editElement?.name}</span>. {t("general.itsContents")}
        </>

    const classType = (type: string) => cn(s.type, {
        [s.type_array]: type === "array",
        [s.type_object]: type === "object",
    });

    const handleUpdateType = async () => {
        try {
            const response = await updateTypeModule({
                id: id,
                name: title,
                type: object[0],
            }).unwrap();

            if (response.message === "ok") {
                openStatus('success', "status.operationSuccessful");
                refetch();
                setIsUpdated(false);
            } else {
                openStatus('error', "status.error");
            }
        } catch (error) {
            console.error("Помилка при оновленні модуля:", error);
        }
    };

    return (
        <div className={s.modules}>
            {title &&
                <HeaderSection
                    title={title}
                    buttonText={t("general.saveChanges")}
                    onClick={handleUpdateType}
                    disabled={!isUpdated}
                />
            }

            {object?.map((module, moduleIndex) => (
                <div className={s.container} key={moduleIndex}>
                    <div className={`${s.module} ${hidden?.includes(module.id) && s.hide}`}>
                        <div className={s.module_header}>
                            <div className={s.main_info}>
                                <div className={s.data}>
                                    <p>{t("pagesModal.name")}:</p>
                                    <span>{module?.name}</span>
                                </div>
                            </div>
                            <span className={classType(module?.type)}>{module?.type}</span>
                            {setting((module?.type === "object" || module?.type === "array"), module)}
                        </div>

                        <div className={s.module_values}>
                            {Array.isArray(module?.values) ? (
                                module?.values?.map((item: any, index: number) => (
                                    <div key={index} className={s.module_values_item}>
                                        <p>{item}</p>
                                    </div>
                                ))
                            ) : module?.values ? (
                                <RenderModuleValues
                                    values={Object.values(module?.values)}
                                    setting={setting}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ))}

            <ModalGeneral
                showModal={openModal}
                setShowModal={setOpenModal}
                title={t("modules.addInfo")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                fieldModule
                buttonRight={addInfo}
                editElement={editElement}
                type={type}
                currentMarkers={currentMarkers}
            />

            <ModalGeneral
                showModal={openModalEdit}
                setShowModal={setOpenModalEdit}
                title={t("modules.editInfo")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.confirm")}
                fieldModule
                buttonRight={editInfo}
                editElement={editElement}
                currentMarkers={currentMarkers}
            />

            <ModalGeneral
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={t("general.delete")}
                textLeftButton={t("general.cancel")}
                textRightButton={t("general.delete")}
                buttonRight={deleteInfo}
                editElement={editElement}
                image={questionMark}
                description={deletedElement}
            />
        </div>
    );
};
