import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const modulesApi = createApi({
    reducerPath: 'modulesApi',
    tagTypes: ['Modules'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({
      getModules: builder.query({
        query: () => `/api/modules/type/get`,
      }),

      createTypeModule: builder.mutation({
        query: (data) => ({
          url: '/api/modules/type/create',
          method: 'POST',
          body: data
        }),
      }),

      deleteTypeModule: builder.mutation({
        query: (data) => ({
          url: '/api/modules/type/delete',
          method: 'DELETE',
          body: data
        }),
      }),

      updateTypeModule: builder.mutation({
        query: (data) => ({
          url: '/api/modules/type/update',
          method: 'PUT',
          body: data
        }),
      }),

      getModule: builder.query({
        query: (params) => ({
          url: '/api/module/type/get',
          method: "GET",
          params
        }),
      }),

      createModule: builder.mutation({
        query: (data) => ({
          url: '/api/modules/create',
          method: 'POST',
          body: data
        }),
      }),

      deleteModule: builder.mutation({
        query: (data) => ({
          url: '/api/modules/delete',
          method: 'DELETE',
          body: data
        }),
      }),

      addToArrayModule: builder.mutation({
        query: (data) => ({
          url: '/api/modules/add/array',
          method: 'POST',
          body: data
        }),
      }),
    }),
})

export const {
  useGetModulesQuery,
  useCreateTypeModuleMutation,
  useDeleteTypeModuleMutation,
  useUpdateTypeModuleMutation,
  useGetModuleQuery,
  useCreateModuleMutation,
  useDeleteModuleMutation,
  useAddToArrayModuleMutation,
} = modulesApi;