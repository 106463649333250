type Lang = { key: string };

export const findById = (
    values: Record<string, any>,
    ids: number[],
    newValues: Record<string, any>,
    langs: Lang[]
): Record<string, any> => {
    let updatedValues = { ...values };

    const updateNestedValues = (value: any, id: number): any => {
        if (!value) return value;

        if (value.id === id) {
            // Оновлюємо значення елемента, якщо id збігається
            langs.forEach((lang) => {
                Object.entries(newValues).forEach(([newKey, newValue]) => {
                    if (newKey.includes(String(id)) && newKey.includes(lang.key)) {
                        const isMedia = value.type === "image" || value.type === "video";
                        const isLink = value.type === "link"; // Перевірка на тип link
                        const existingValues = value.values || [];
                        const exists = existingValues.some(
                            (item: any) =>
                                item.lang_key === lang.key &&
                                (item.file === newValue || item.value === newValue)
                        );

                        if (!exists) {
                            const updatedItem = isMedia
                                ? { file: newValue, lang_key: lang.key }
                                : { value: newValue, lang_key: lang.key };

                            value.values = [...existingValues, updatedItem];
                        }

                        if (isLink) {
                            for (let key in newValues) {
                                if (key === "link") {
                                    value.link = newValues[key];
                                }
                            }
                        }
                    }
                });
            });
            return value;
        }

        // Рекурсивно обробляємо вкладені масиви
        if (Array.isArray(value.values)) {
            value.values = value.values.map((nestedValue: any) =>
                updateNestedValues(nestedValue, id)
            );
        }

        // Рекурсивно обробляємо вкладені об'єкти
        if (typeof value === "object") {
            Object.entries(value).forEach(([key, nestedValue]) => {
                value[key] = updateNestedValues(nestedValue, id);
            });
        }

        return value;
    };

    ids.forEach((id) => {
        Object.entries(updatedValues).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                // Якщо значення є масивом
                updatedValues[key] = value.map((item: any) =>
                    updateNestedValues(item, id)
                );
            } else if (typeof value === "object" && value !== null) {
                // Якщо значення є об'єктом
                updatedValues[key] = updateNestedValues(value, id);
            }
        });
    });

    return updatedValues;
};


export const extractIds = (input: Record<string, any>): number[] => {
    const ids = new Set<number>();

    Object.keys(input).forEach((key) => {
        const match = key.match(/(\d+)-\w{2}$/);
        if (match) {
            const id = parseInt(match[1], 10);
            ids.add(id);
        }
    });

    return Array.from(ids);
};
