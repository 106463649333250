import React from 'react';
import s from './Preloader.module.scss';
import loading from 'assets/icons/loading.svg';

export const Preloader = () => {

    return(
        <div className={s.preloader}>
            <img src={loading} alt="loading" />
        </div>
    )
}