import React, {useState} from 'react';
import s from './Auth.module.scss';
import background from 'assets/images/background.webp';
import logo from 'assets/icons/logo.svg';

import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {useAuthMutation} from "../../redux/apis/userApi";
import {useCrypto} from "utils/crypto";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setIsLoading} from "../../redux/slices/mainSlice";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {Input} from "components/Input/Input";
import {useTranslation} from "react-i18next";

export const Auth = () => {
    const [auth, {data, isLoading, isError}] = useAuthMutation();
    let response = "";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [passwordLength, setPasswordLength] = useState(0);
    const { t } = useTranslation();

    const initialValues = { email: '', password: ''}
    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .required(t("validation.required"))
            .min(14, t("validation.min"))
            .matches(/[A-Z]/, t("validation.oneCapitalLetter"))
            .matches(/[0-9]/, t("validation.oneDigit")),

        email: yup.string().required(t("validation.required")).email(t("validation.incorrectEmail"))
    });

    function LogIn(values: any){
        const email = values.email;
        const password = useCrypto(values.password);
        auth({email: email, password: password});
    }

    if (isError) console.log(isError);
    if (isLoading) dispatch(setIsLoading(true));
    else dispatch(setIsLoading(false));
    if (data) {
        if(data.message === 8) response = t("validation.incorrectEmailOrPassword");
        if(data.message === 10){
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("userData", JSON.stringify(data.data.userData));
            navigate("/");
        }
    }

    return (
        <div className={s.container}>
            <div className={s.login}>
                <img
                    src={logo}
                    alt={s.logo}
                    className={s.logo}
                />

                <div className={s.wrap_form}>
                    <h1>{t("auth.signIn")}</h1>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values)=> LogIn(values)}
                    >
                        {({
                              errors,
                              touched,
                              isValid,
                              dirty,
                              setFieldValue
                        }) => (
                            <Form className={s.auth_form}>

                                <Input
                                    label={t("users.email")}
                                    errors={errors.email}
                                    touched={touched.email}
                                    name={"email"}
                                    placeholder={t("auth.enterYourEmail")}
                                    onClick={() => setFieldValue('email', '')}
                                />

                                <Input
                                    label={t("addUser.password")}
                                    errors={errors.password}
                                    touched={touched.password}
                                    name={"password"}
                                    placeholder={t("auth.enterYourPassword")}
                                    password
                                />

                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || !dirty}
                                    text={"Войти"}
                                />

                                <span className={s.error_text}>{response}</span>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <div className={s.image}>
                <img src={background} alt={s.loginImage} />
            </div>
        </div>
    )
}