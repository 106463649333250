import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Auth} from "./pages/Auth/Auth";
import {Main} from "./pages/Main/Main";
import {StatusProvider} from "./components/StatusProvider";
import {Status} from "./components/Status/Status";

function App() {
  return (
      <StatusProvider>
          <div className="App">
              <Routes>
                  <Route path="*" element={<Main />} />
                  <Route path="/auth" element={<Auth />} />
              </Routes>
              <Status />
          </div>
      </StatusProvider>
  );
}

export default App;
