import React, { useEffect, useState } from 'react';
import s from './DropDown.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import { Field, FormikErrors, FormikValues } from "formik";
import 'react-quill/dist/quill.snow.css';
import QuillEditor from "../QuillEditor/QuillEditor";

type DropDownProps = {
    langs: any;
    errors: any;
    touched: any;
    kind: string;
    label: string;
    module?: boolean;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
    values?: FormikValues;
};

export const DropDown = ({ langs, errors, touched, kind, label, module, setFieldValue, values }: DropDownProps) => {
    const [hideStates, setHideStates] = useState<{ [key: string]: boolean }>({});
    const [selectedLang, setSelectedLang] = useState<string>(langs[0]?.key || '');
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const file = event.target.files?.[0];

        if (file && setFieldValue) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFieldValue(`${kind}-${key}`, reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const initialState = langs.reduce((acc: { [key: string]: boolean }, lang: any) => {
            acc[lang.key] = true;
            return acc;
        }, {});
        setHideStates(initialState);
    }, [langs]);

    const toggleHide = (key: string) => {
        setHideStates(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    const handleLanguageChange = (langKey: string) => {
        setSelectedLang(langKey);
    };

    const isValidUrl = (url: string) => {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    };

    const renderContent = (v: any) => {
        const fieldName = `${kind}-${v.key}`;
        const value = values?.[fieldName];

        if (label === "image") {
            return (
                <div className={s.image_wrapper}>
                    {value && <img src={value && isValidUrl(value) ? value : `${API_ADDRESS}${value}`}  alt="Uploaded" className={s.image} />}
                    <Field
                        className={s.change_input}
                        name={`${fieldName}-image`}
                        type="file"
                        accept="image/*"
                        onChange={(e: any) => handleImageUpload(e, v.key)}
                    />
                </div>
            );
        }

        if (label === "video") {
            return (
                <div className={s.image_wrapper}>
                    {value && <video src={value && isValidUrl(value) ? value : `${API_ADDRESS}${value}`} className={s.image} controls />}
                    <Field
                        className={s.change_input}
                        name={`${fieldName}-video`}
                        type="file"
                        accept="video/*"
                        onChange={(e: any) => handleImageUpload(e, v.key)}
                    />
                </div>
            );
        }

        return <Field name={fieldName} component={QuillEditor} />;
    };

    return (
        <div className={s.dropdown}>
            <div className={s.languageButtons}>
                {(kind !== "image" && kind !== "video") && langs?.map((v: any) => (
                    <div
                        key={v.key}
                        onClick={() => handleLanguageChange(v.key)}
                        className={`${s.languageButton} ${selectedLang === v.key ? s.selected : ''}`}
                    >
                        {v.key}
                    </div>
                ))}
            </div>

            {(kind === "image" || kind === "video") ? (
                <div className={s.content_row}>
                    {langs?.map((v: any) => (
                        <div key={v.key} className={s.flex}>
                            {!module && (
                                <div className={s.content_head}>
                                    <p>{label} <span>{v.key}</span></p>
                                </div>
                            )}

                            {renderContent(v)}

                            {touched?.[`${kind}-${v.key}`] && errors?.[`${kind}-${v.key}`] && (
                                <span className={s.error_text}>
                                    {errors[`${kind}-${v.key}`]}
                                  </span>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                langs?.map((v: any) => {
                    const contentClass = `${s.content} ${!hideStates[v.key] && s.hide} ${
                        v.key !== selectedLang && s.none
                    }`;

                    return (
                        <div key={v.key} className={contentClass}>
                            {!module && (
                                <div className={s.content_head}>
                                    <p>
                                        {label} <span>{v.key}</span>
                                    </p>
                                    <ArrowTools onClick={() => toggleHide(v.key)} className={s.arrow} />
                                </div>
                            )}

                            {renderContent(v)}

                            {touched?.[`${kind}-${v.key}`] && errors?.[`${kind}-${v.key}`] && (
                                <span className={s.error_text}>
                                    {errors[`${kind}-${v.key}`]}
                                  </span>
                            )}
                        </div>
                    );
                })
            )}
        </div>
    );
};
