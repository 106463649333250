export function getFormattedDate(i18n: any): string {

    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
    };

    const today: Date = new Date();
    const locale = i18n.language === 'en' ? 'en-US' : 'ru-RU';
    const formattedDate: string = today.toLocaleDateString(locale, options);

    const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

    return capitalizedDate;
}