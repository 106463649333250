import React, {useEffect, useState} from 'react';
import s from './DropDownSecond.module.scss';
import { ReactComponent as ArrowTools } from "assets/icons/arrow_tools.svg";
import {NavigateFunction} from "react-router-dom";
import ReactQuill from "react-quill";
import {useTranslation} from "react-i18next";

type DropDownSecond = {
    langs?: any;
    label?: string;
    value?: any;
    refDrop?: any;
    onChange?: any;
    el?: any;
    image?: boolean;
    values?: any;
    video?: boolean;
    videoRef?: any;
    navigate: NavigateFunction;
    defaultImage?: any;
    setDefaultImage?: React.Dispatch<any>;
    activeImages?: any;
};

export const DropDownSecond = ({
                                   langs,
                                   label,
                                   value,
                                   refDrop,
                                   onChange,
                                   el,
                                   image,
                                   values,
                                   video,
                                   videoRef,
                                   navigate,
                                   defaultImage,
                                   setDefaultImage,
                                   activeImages
                               }: DropDownSecond) => {
    const [hideStates, setHideStates] = useState<{ [key: string]: boolean }>({});
    const [selectedLang, setSelectedLang] = useState<string>(langs?.[0]?.key || '');
    const { t } = useTranslation();
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

    useEffect(() => {
        if (langs) {
            const initialState = langs.reduce(
                (acc: { [key: string]: boolean }, lang: any) => {
                    acc[lang.key] = true;
                    return acc;
                },
                {}
            );
            setHideStates(initialState);
        }
    }, [langs]);

    const toggleHide = (key: string) => {
        setHideStates((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleImageChange = (key: string, ev: any) => {
        onChange(ev.target, key, values.file?.find((el: any) => el.lang_key === key)?.id);
    };

    const handleLangChange = (key: string) => {
        setSelectedLang(key);
    };

    return (
        <div className={s.dropdown}>
            {(image || video) && (
                <div className={s.content_row}>
                    {langs?.map((ln: any, index: number) => (
                        <div className={s.flex_column}>
                            <p> {label} <span>{ln.key}</span> </p>
                            {image ?
                                <div
                                    key={index}
                                    className={s.image_wrapper}
                                    ref={(el) => (refDrop.current[ln.key] = el)}
                                    style={{
                                        backgroundImage: `url(${API_ADDRESS}${values?.file?.find(
                                            (el: any) => el.lang_key === ln.key
                                        )?.link})`,
                                    }}
                                >
                                    <input
                                        className={s.change_input}
                                        name="image"
                                        type="file"
                                        accept="image/*"
                                        onChange={(ev: any) => handleImageChange(ln.key, ev)}
                                    />
                                </div> :
                                <div
                                    className={s.image_wrapper}
                                    ref={(el) => (refDrop.current[ln.key] = el)}
                                    style={{
                                        backgroundImage: `url(${API_ADDRESS}${values?.file?.find(
                                            (el: any) => el.lang_key === ln.key
                                        )?.link})`,
                                    }}
                                >
                                    <input
                                        className={s.change_input}
                                        name="video"
                                        type="file"
                                        accept="video/*"
                                        onChange={(ev) => handleImageChange(ln.key, ev)}
                                    />
                                    <div className={s.video_wrapper}>
                                        <video
                                            playsInline
                                            ref={(el) => (videoRef.current[ln.key] = el)}
                                            src={values.file?.find((el: any) => el.lang_key === ln.key)?.link}
                                            controls={!!values.file?.path}
                                            style={{width: '100%', height: '100%'}}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                    ))}
                </div>
            )}

            <div className={s.lang_switcher}>
                {(!image && !video) && langs?.map((ln: any) => (
                    <div
                        key={ln.key}
                        className={`${s.lang_button} ${selectedLang === ln.key ? s.active : ''}`}
                        onClick={() => handleLangChange(ln.key)}
                    >
                        {ln.key}
                    </div>
                ))}
            </div>
            {(!image && !video) && <label>{t("language.langs")}</label>}
            {(!image && !video) && langs?.map((ln: any, index: number) => (
                <div className={`${s.content} ${!hideStates[ln.key] && s.hide} ${selectedLang !== ln.key && s.none}`}
                     key={ln.key}>
                    <div className={s.content_head}>
                        <p>
                            {label} <span>{ln.key}</span>
                        </p>

                        <ArrowTools onClick={() => toggleHide(ln.key)} className={s.arrow}/>
                    </div>

                    <ReactQuill
                        data-refval={ln.id}
                        value={refDrop.current[ln.key]?.value ?? value[index]?.value}
                        ref={(ref) => (refDrop.current[ln.key] = ref)}
                        onChange={(content) => onChange({ target: { value: content } }, 'quill')}
                        readOnly={false}
                    />

                    {defaultImage && (
                        <div className={s.default_wrapper}>
                            <input
                                type="checkbox"
                                defaultChecked={defaultImage?.lang_key === ln.key}
                                checked={ln.key === defaultImage?.lang_key}
                                onChange={() =>
                                    setDefaultImage &&
                                    setDefaultImage({
                                        lang_key: ln.key,
                                        id: values.file?.find((el: any) => el.lang_key === ln.key)?.id,
                                    })
                                }
                                disabled={!activeImages[ln.key]}
                            />
                            <span>{t('general.default')}</span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
