import { useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
     useAddBlockMutation,
    useGetBlocksTypesQuery, useUpdateBlockMutation,
} from "../../../../redux/apis/blocksApi";
import {useGetTermsQuery} from "../../../../redux/apis/termsApi";
import {useGetLangsQuery} from "../../../../redux/apis/langApi";
import {useGetMediaQuery} from "../../../../redux/apis/mediaApi";
import {Preloader} from "components/Preloader/Preloader";

import s from './BlocksModal.module.scss';
import {Back} from "components/Back/Back";
import {Select} from "components/Select/Select";
import {DropDownSecond} from "components/DropDownSecond/DropDownSecond";
import {PrimaryButton} from "components/PrimaryButton/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton/SecondaryButton";
import { ReactComponent as Check } from 'assets/icons/check.svg';
import {useStatusContext} from "components/StatusProvider";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {blobToBase64} from "utils/getImages";

type BlocksModal = {
    close: any;
    section: any;
    values: any;
    lang_data: any;
    type?: any;
    accept?: any;
    tm_name?: any;
    post_body?: any;
    refetchGetSection: any;
}

export const BlocksModal = ({ close, section, values, lang_data, type, accept, tm_name, post_body, refetchGetSection }: BlocksModal) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const ln = useSelector((store: any) => store.main.langs)
    const marker_ref: any = useRef();
    const new_group_ref: any = useRef();
    const img_wrapper: any = useRef({});
    const group_select: any = useRef();
    const type_select: any = useRef();
    const error_text: any = useRef();
    const video: any = useRef({});
    const terms_values: any = useRef([]);
    const extra_values: any = useRef([]);

    const [formContent, setFormContent] = useState<any>();
    const [content_type, setType] = useState('text');
    const [inputsValues, setInputsValues] = useState({
        ...values?.terms_block,
        'link': {value: values?.link? values.link : ''},
        'pseudo': {value: values?.pseudo? values.pseudo : ''},
        'alt': {value: values?.file?.[0]?.alt? values?.file?.[0]?.alt : ''},
        'marker': {value: values?.marker? values.marker : ''}
    });
    const [defaultImage, setDefaultImage] = useState<any>(undefined)
    const [activeImages, setActiveImages] = useState<any>({});

    const [addBlockNew, {isLoading: isAddBlockLoading}] = useAddBlockMutation();
    const [updateBlockNew, {isLoading: isUpdateBlockLoading}] = useUpdateBlockMutation();

    const {data, isError, isFetching} = useGetBlocksTypesQuery({});
    const terms = useGetTermsQuery({});
    const lang = useGetLangsQuery({});
    const media = useGetMediaQuery({});
    const { openStatus } = useStatusContext();

    useEffect(()=> {
        values?.file?.forEach((file: any) => {
            if(file.default_image) setDefaultImage({lang_key: file.lang_key, id: undefined})
        })

    },[]);

    useEffect(()=>{
        values?.file?.forEach((el: any) => {
            if (el.link)
                setActiveImages((prev: any) => {return { ...prev, [el.lang_key]: true }})
        })

    },[values.file]);

    if (isFetching || terms.isFetching || lang.isFetching || media.isFetching || isAddBlockLoading || isUpdateBlockLoading) return <Preloader/>
    if (isError) console.log(isError);

    // ### SET CONTENT TYPE

    if (!formContent) {
        if(values?.type) changeType(values?.type);
        else if(type) changeType(type);
        else changeType("text");
        return null;
    }

    // ### FUNCTIONS

    function changeType(value: string){
        switch(value){
            case "text":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('text')
                break;

            case "link":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('link')
                break;

            case "button":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('button')
                break;

            case "image":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('image')
                break;

            case "video":
                setFormContent(data?.data.find((el: any) => el.name === value).options[0])
                setType('video')
                break;

            default: openStatus('error', "status.notRightType");
        }
    }


    async function sendForm(ev: any){

        let err_flag = false;
        error_text.current.innerHTML = "";
        ev.preventDefault();

        const pseudo = inputsValues['pseudo'].value;
        const marker = inputsValues['marker'].value;
        const old_group = group_select?.current?.value;
        const new_group = new_group_ref?.current?.value;
        const group = new_group?.length? new_group : old_group;
        const link = extra_values?.current['link']?.value? extra_values?.current['link']?.value : '';
        const files = extra_values?.current;
        const alt = extra_values.current['alt']?.value;

        if (marker.length < 2) {
            error_text.current.innerHTML = t("validation.fillInTheMarker");
            err_flag = true;
        }
        lang?.data.data.forEach((el: any) => {
            if(terms_values?.current[el.key]?.value.length < 1) {
                error_text.current.innerHTML = t("validation.fillInAllFields");
                err_flag = true;
            }
        });

        if (err_flag) return;

        if (accept) {
            const result_terms : any = {}
            result_terms.text = lang.data.data.map((el: any) => {
                return {value: terms_values.current[el.key].value, pseudo: pseudo, group: group, lang_id: el.id}
            });
            if(tm_name) result_terms.tm_name = tm_name;
            if(post_body) result_terms.post_body = "post_body";
            accept(result_terms);
            close(false);
            return null;
        }

        let terms: any[] = [];
        lang?.data.data.map( async (el: any) => {
            terms.push({
                value: terms_values?.current[el.key]?.value ?? "test",
                lang_key: el.key
            })
        });

        // Edit block
        if (values.type){
            const response: any = await updateBlockNew({
                type: content_type,
                section_id: section.id,
                link: link,
                terms: terms,
                marker: inputsValues.marker.value,
                files: files?.length !== 0 ? files?.map((file: any) => ({
                    ...file,
                    default_image: file.lang_key === defaultImage?.lang_key,
                })): values?.file?.map((file: any)  => ({
                    lang_key: file.lang_key,
                    default_image: file.lang_key === defaultImage?.lang_key
                })),
                alt: alt,
                block_id: values.block_id
            });
            if (response?.error?.data?.message === 1000){
                openStatus('warning', "status.unfortunately");
                close(false);
            }
            else if (response?.data?.message === "block_successfully_updated") {
                openStatus('success', "status.operationSuccessful");
                refetchGetSection();
                close(false);
            }
            return;
        }

        // Add new block
        const response: any = await addBlockNew({
            type: content_type,
            section_id: section.id,
            link: link,
            terms: terms,
            marker: inputsValues.marker.value,
            files: files?.map((file: any) => ({
                ...file,
                default_image: file.lang_key === defaultImage?.lang_key,
            })),
            alt: alt
        });

        if (response.error?.data?.message === 1000) {
            openStatus('warning', "status.unfortunately");
            close(false);
        }
        else if (response.data?.message === "block_successfully_created") {
            openStatus('success', "status.operationSuccessful");
            refetchGetSection();
            close(false);
        }
    }

    async function previewImage(input: any, lnkey: any, id: number) {
        if (input.name === "image") {
            const reader = new FileReader();
            reader.onload = async function (e: any) {
                img_wrapper.current[lnkey].style.cssText = `background: url(${e.target.result}) no-repeat center; background-size: contain;`;
                setActiveImages((prev: any) => ({ ...prev, [lnkey]: true }));
                extra_values.current = extra_values.current.filter((file: any) => file.lang_key !== lnkey);

                if (input.files && input.files[0]) {
                    try {
                        if (!defaultImage) setDefaultImage({ lang_key: lnkey, id: undefined });

                        const base64 = await blobToBase64(input.files[0]);
                        extra_values.current.push({
                            lang_key: lnkey,
                            file: base64,
                        });
                    } catch (error) {
                        console.error('Error converting blob to base64:', error);
                    }
                }
            };
            reader.readAsDataURL(input.files[0]);
        }

        if (input.name === "video") {
            const reader = new FileReader();
            reader.onload = async function (e: any) {
                video.current[lnkey].src = e.target.result;
                video.current[lnkey].setAttribute('controls', true);

                extra_values.current = extra_values.current.filter((file: any) => file.lang_key !== lnkey);

                try {
                    if (!defaultImage) setDefaultImage({ lang_key: lnkey, id: undefined });
                    const base64 = await blobToBase64(input.files[0]);
                    extra_values.current.push({
                        lang_key: lnkey,
                        file: base64,
                    });
                } catch (error) {
                    console.error('Error converting blob to base64:', error);
                }

                setActiveImages((prev: any) => ({ ...prev, [lnkey]: true }));
            };

            if (input?.files?.[0]) {
                reader.readAsDataURL(input.files[0]);
            } else {
                console.error('No file selected');
            }
        }
    }

    function changeInput(el: any, quill?: string){
        console.log(el);
        setInputsValues((prev: any) => {
            const newState = JSON.parse(JSON.stringify(prev));

            if (quill) {
                if (newState?.['data-refval']) {
                    newState['data-refval'].value = el.value;
                }
            } else {
                if (newState?.[el.getAttribute('data-refval')]) newState[el.getAttribute('data-refval')].value = el.value;
            }

            return newState;
        });
    }

    return (
        <div className={s.pages_modal}>
            <Back close={close} text={t("blocksModal.backToBlocks")} />

            <div className={s.form}>
                <h2>{values?.type ? t("blocksModal.modifyBlock") : t("pages.addABlock")}</h2>

                <form className={s.pages_form}>
                    <div className={s.form_row}>
                        <label>{t("general.type")}</label>
                        <Select
                            values={data?.data}
                            change={changeType}
                            refSelect={type_select}
                            defaultValue={values?.type ? values?.type: "text"}
                            disable={values?.type}
                        />
                    </div>

                    {
                        formContent?.map((el: any, index: number) => {
                            if (el.label === t("blocksModal.text")) {
                                return (
                                    <DropDownSecond
                                        langs={lang?.data?.data}
                                        label={t("blocksModal.text")}
                                        value={inputsValues}
                                        refDrop={terms_values}
                                        onChange={changeInput}
                                        el={el.label}
                                        navigate={navigate}
                                    />
                                )
                            } else if (el.label === t("pages.link")) {
                                return (
                                    <div key={ln.id} className={s.form_row}>
                                        <label>{el.label}</label>
                                        <input
                                            type={el.type}
                                            value={inputsValues['link'].value}
                                            name={el.name}
                                            onChange={(ev) => changeInput(ev.target)}
                                            ref={ref => extra_values.current[el.name] = ref}
                                            data-refval="link"
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index} className={s.form_row}>
                                        {el.name === 'alt' ?
                                            <>
                                                <label>{el.label}</label>
                                                <input
                                                    type={el.type}
                                                    name={el.name}
                                                    value={inputsValues['alt'].value}
                                                    onChange={(ev) => changeInput(ev.target)}
                                                    ref={ref => extra_values.current[el.name] = ref}
                                                    data-refval={'alt'}
                                                />
                                            </> : el.name === 'image' ?
                                                <div className={s.image_items_wrapper}>
                                                    <DropDownSecond
                                                        langs={ln}
                                                        label={t("blocksModal.image")}
                                                        value={inputsValues}
                                                        refDrop={img_wrapper}
                                                        onChange={previewImage}
                                                        el={el.label}
                                                        values={values}
                                                        image
                                                        navigate={navigate}
                                                        defaultImage={defaultImage}
                                                        setDefaultImage={setDefaultImage}
                                                        activeImages={activeImages}
                                                    />
                                                </div>
                                                :
                                                <div className={s.image_items_wrapper}>
                                                    <DropDownSecond
                                                        langs={ln}
                                                        label={t("blocksModal.video")}
                                                        value={inputsValues}
                                                        refDrop={img_wrapper}
                                                        onChange={previewImage}
                                                        el={el.label}
                                                        values={values}
                                                        video
                                                        videoRef={video}
                                                        navigate={navigate}
                                                        defaultImage={defaultImage}
                                                        setDefaultImage={setDefaultImage}
                                                        activeImages={activeImages}
                                                    />
                                                </div>
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                    <div className={s.form_row}>
                        <label>{t("pages.marker")}</label>
                        <input
                            onChange={(ev) => changeInput(ev.target)}
                            data-refval='marker'
                            type="text"
                            name="marker"
                            value={inputsValues['marker'].value}
                            ref={marker_ref}
                            disabled={false}
                        />
                    </div>
                    <span className="error_text" ref={error_text}></span>
                    <div className={s.wrap_buttons}>
                        <PrimaryButton
                            text={t("general.confirm")}
                            type={"submit"}
                            icon={<Check />}
                            onClick={(ev) => sendForm(ev)}
                        />
                        <SecondaryButton
                            text={t("general.cancel")}
                            type={"button"}
                            white
                            onClick={() => close(false)}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}