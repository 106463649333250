import React, {ButtonHTMLAttributes} from "react";
import s from "./PrimaryButton.module.scss";

type PropsButton = ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
    type: "submit" | "reset" | "button" | undefined;
    text: string;
    className?: string;
    icon?: any;
}

export const PrimaryButton = ({ disabled, type, text, className, icon, ...props }: PropsButton) => {
    return (
        <button
            type={type}
            disabled={disabled}
            className={`${s.primary} ${className}`}
            {...props}
        >
            {text}{icon && icon}
        </button>
    )
}