import React, {useEffect, useMemo, useState} from 'react';
import s from './Dashboard.module.scss';
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import {useGetPermissionsQuery, useGetUsersQuery} from "../../redux/apis/userApi";
import {useNavigate} from "react-router-dom";
import {getFormattedDate} from "utils/getFormattedDate";
import {useGetSectionsQuery} from "../../redux/apis/sectionApi";
import {HeaderSection} from "components/HeaderSection/HeaderSection";
import {useTranslation} from "react-i18next";
import {useGetPagesQuery} from "../../redux/apis/pagesApi";

export const Dashboard = () => {
    const { data: users } = useGetUsersQuery({});
    const {currentData: sections} = useGetSectionsQuery({});
    const {currentData: pages} = useGetPagesQuery({});
    const navigate = useNavigate();
    const {data: permissions} = useGetPermissionsQuery({});
    const allowPagesEdit = permissions?.permissions?.pages;
    const userDataString = localStorage.getItem('userData');
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [activePage, setActivePage] = useState<any>();

    useEffect(() => {
        if (pages) {
            setActivePage(pages?.data?.[0]?.content?.[0]);
        }

    }, [pages]);

    const navigateToEdit = (section: any) => {
        navigate("/pages", { state: { section: section} });
    }

    const handlePage = (id: any) => {
        setActivePage(id);
    }

    const lightenColor = (hex: string, percent: number = 20) => {
        let r: number = parseInt(hex.slice(1, 3), 16);
        let g: number = parseInt(hex.slice(3, 5), 16);
        let b: number = parseInt(hex.slice(5, 7), 16);

        r = Math.round(r + (255 - r) * (percent / 100));
        g = Math.round(g + (255 - g) * (percent / 100));
        b = Math.round(b + (255 - b) * (percent / 100));

        return `rgb(${r}, ${g}, ${b})`;
    };

    const generateRandomColor = () => {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    };

    const userColors = useMemo(() => {
        const colors: Record<string, string> = {};
        users?.forEach((user: any) => {
            colors[user.id] = generateRandomColor();
        });
        return colors;
    }, [users]);

    return (
        <div className={s.dashboard}>
            <HeaderSection
                title={t("dashboard.homePage")}
                buttonText={t("dashboard.customize")}
                icon={<Brush />}
                disabled
            />

            <section className={s.dashboard_welcome}>
                <p>{getFormattedDate(i18n)}</p>
                <h2>{t("dashboard.hello")}, {userData?.info?.name}</h2>
            </section>

            <section className={s.dashboard_main}>
                <div className={s.container}>
                    <div className={s.header}>
                        <p className={s.title}>{t("dashboard.users")} <span>({ users?.length } user)</span></p>
                        <div className={s.container_button} onClick={() => navigate("/users")}>{t("dashboard.all")}</div>
                    </div>

                    <div className={s.container_overflow}>
                        {users?.map((user: any) => {
                            const color = userColors[user.id];
                            const lighterColor = lightenColor(color, 60);

                            return (
                                <div className={s.container_wrap}>
                                    <div className={s.container_detail}>
                                        <div
                                            className={s.image}
                                            style={{backgroundColor: color, borderColor: lighterColor}}
                                        >
                                            <span className={s.letter} style={{color: lighterColor}}>{user?.info?.name?.[0]}</span>
                                        </div>
                                        <p className={s.name}>{user?.info?.name}</p>
                                        <p className={s.email}>{user?.email}</p>
                                    </div>

                                    <div className={s.container_role}>{user?.info?.role}</div>
                                </div>
                        )})}
                    </div>
                </div>

                <div className={s.container}>
                    <div className={s.header}>
                        <p className={s.title}>{t("dashboard.pagesModules")}</p>
                        <div className={s.container_button} onClick={() => navigate("/pages")}>
                            {t("dashboard.all")}
                        </div>
                    </div>

                    <div className={s.pages}>
                        {pages?.data?.map((page: any) => (
                            <p className={`${s.page} ${(activePage === page.content[0]) && s.page_active}`} onClick={() => handlePage(page.content[0])}>
                                {page.name}
                            </p>
                        ))}
                    </div>

                    <div className={s.container_overflow}>
                        {sections?.data
                            ?.filter((section: any) => section.id === activePage)
                            .map((section: any) => (
                                <div className={`${s.container_wrap} ${s.container_modules} ${!allowPagesEdit && s.disabled}`}>
                                    <p className={`${s.name} ${s.section}`}>{section?.name}</p>
                                    <div className={s.edit} onClick={() => allowPagesEdit && navigateToEdit(section)}>
                                        {t("general.edit")}
                                    </div>
                                </div>
                            ))}

                    </div>
                </div>
            </section>
        </div>
    )
}