import React, {useEffect, useState} from 'react';
import {useAddUserMutation, useUpdateUserMutation} from "../../redux/apis/userApi";
import {useCrypto} from "utils/crypto";
import s from "./AddUser.module.scss";
import { Form, Formik} from "formik";
import * as yup from 'yup';
import {Back} from "../Back/Back";
import {SecondaryButton} from "../SecondaryButton/SecondaryButton";
import {PrimaryButton} from "../PrimaryButton/PrimaryButton";
import {Input} from "../Input/Input";
import {useStatusContext} from "../StatusProvider";
import {useTranslation} from "react-i18next";
import {Select} from "../Select/Select";
import {useGetModulesQuery} from "../../redux/apis/modulesApi";

type AddUser = {
    close: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    user?: any;
}

export const AddUser = ({ close, user }: AddUser) => {
    const [isOpenCheckbox, setIsOpenCheckbox] = useState<boolean>(false);
    const { openStatus } = useStatusContext();
    const { t } = useTranslation();
    const modules = useGetModulesQuery({});
    const [modulesId, setModulesId] = useState<number[]>(user ? user?.permissions?.modules?.map((per: any) => Number(per)) : []);
    const [openSelect, setOpenSelect] = useState<boolean>(modulesId?.length !== 0);
    const [permissions, setPermissions] = useState({
            "langs": user?.permissions?.langs ?? false,
            "pages": user?.permissions?.pages ?? false,
            "modules": user?.permissions?.modules ?? []
    });

    console.log(permissions);

    useEffect(()=>{
        if (user) setPermissions(user?.permissions)
    },[]);

    console.log(modulesId);

    useEffect(() => {
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            ["modules"]: modulesId,
        }));
    }, [modulesId]);

    console.log(user);

    const [addUser] = useAddUserMutation();
    const [updUser] = useUpdateUserMutation();

    const initialValues = {
        email: user ? user.email : '',
        name: user ? user?.info?.name:'',
        password: user ? "WQf89D11Ixp55a" : '',
        confirmPassword: user ? "WQf89D11Ixp55a" : '',
        role: user ? user?.info?.role : '',
    }

    const validationSchema = yup.object().shape({
        email: yup.string().required(t("validation.required")).email(t("validation.incorrectEmail")),
        password: yup
            .string()
            .required(t("validation.required"))
            .min(14, t("validation.min"))
            .matches(/[A-Z]/, t("validation.oneCapitalLetter"))
            .matches(/[0-9]/, t("validation.oneDigit")),
        confirmPassword: yup.string().required(t("validation.required")).oneOf([yup.ref('password')], t("validation.passwordsDMatch")),
        name: yup.string().required(t("validation.required")),
        role: yup.string().required(t("validation.required")),
    });

    async function Registration(values: any){
        const email = values.email;
        const password = useCrypto(values.password);
        const name = values.name;
        const role = values.role;

        const result = await addUser({
            email: email,
            password: password,
            name: name,
            role: role,
            permissions: permissions
        });

        if (result.data) {
            if(result.data.message === 1) {
                openStatus('error', `${t("users.email")} ${result.data.data} ${t("users.alreadyRegistered")}`);
            }
            if (result.data.message === "registration_successful") {
                close(false);
                openStatus('success', "status.operationSuccessful");
            }
        } else {
            openStatus('error', "status.thereWasAnError");
        }
    }

    async function UpdateUser(values: any){
        const user_id = user.id;
        const email = values.email;
        const name = values.name;
        const role = values.role;

        const result = await updUser({
            user_id: user_id,
            email: email,
            role: role,
            name: name,
            permissions: permissions
        });

        if (result.data) {
             if (result.data.message === "user_successfully_updated") {
                 close(false);
                 openStatus('success', "status.operationSuccessful");
             }
        } else {
            openStatus('error', "status.thereWasAnError");
        }
    }

    function selectPermission(el: any, value: any, group: string) {
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [group]: value,
        }));
    }

    return (
        <div className={s.add_user}>
            <Back close={close} text={t("addUser.backToUsers")} />

            <div className={s.form}>
                <h1>{user ? t("addUser.editUser"): t("users.addUser")}</h1>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        if (!user) Registration(values);
                        else UpdateUser(values);
                    }}
                >
                    {({errors, touched, setFieldValue}) => (
                        <Form className={s.auth_form}>
                            <div className={s.auth_form_border}>
                                <div className={s.auth_form_field}>
                                    <div className={s.form_row}>
                                        <Input
                                            label={t("users.email")}
                                            errors={errors.email}
                                            touched={touched.email}
                                            name={"email"}
                                            placeholder={t("addUser.enterAddress")}
                                            onClick={() => setFieldValue('email', '')}
                                        />

                                        <Input
                                            label={t("pagesModal.name")}
                                            errors={errors.name}
                                            touched={touched.name}
                                            name={"name"}
                                            placeholder={t("addUser.enterYourUserName")}
                                            onClick={() => setFieldValue('name', '')}
                                        />
                                    </div>

                                    <div className={s.form_row}>
                                        <Input
                                            label={t("addUser.password")}
                                            errors={errors.password}
                                            touched={touched.password}
                                            name={"password"}
                                            placeholder={t("addUser.enterYourPassword")}
                                            isDisabled={!!user}
                                            password
                                        />

                                        <Input
                                            label={t("addUser.confirmPassword")}
                                            errors={errors.confirmPassword}
                                            touched={touched.confirmPassword}
                                            name={"confirmPassword"}
                                            placeholder={t("addUser.confirmPassword")}
                                            isDisabled={!!user}
                                            password
                                        />
                                    </div>

                                    <Input
                                        label={t("users.role")}
                                        errors={errors.role}
                                        touched={touched.role}
                                        name={"role"}
                                        placeholder={t("addUser.enterRole")}
                                        onClick={() => setFieldValue('role', '')}
                                    />
                                </div>

                                <div className={s.access}>
                                    <h2>{t("addUser.accessRights")}</h2>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>{t("addUser.moduleManagement")}</p>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-modules"
                                                value="view"
                                                defaultChecked={user && user?.permissions?.modules?.length === 0}
                                                onChange={(ev) => {
                                                    setOpenSelect(false);
                                                    setModulesId([]);
                                                }}
                                            />
                                            {t("addUser.viewOnly")}
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-modules"
                                                value="edit"
                                                defaultChecked={user && user?.permissions?.modules?.length !== 0}
                                                onChange={(ev) => {
                                                    setOpenSelect(true);
                                                }}
                                            />
                                            {t("addUser.editing")}
                                        </label>

                                        {openSelect &&
                                            <Select
                                                values={modules?.data}
                                                label={"Select modules"}
                                                defaultValue={"Modules"}
                                                setModulesId={setModulesId}
                                                modulesId={modulesId}
                                                modules
                                            />
                                        }
                                    </div>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>{t("addUser.editingLanguages")}</p>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-langs"
                                                value="langs"
                                                defaultChecked={permissions?.langs}
                                                onChange={(ev) =>
                                                    selectPermission(ev.target, true, "langs")
                                                }
                                            />
                                            {t("addUser.yes")}
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-langs"
                                                value="no-langs"
                                                defaultChecked={user ? !user?.permissions?.langs: false}
                                                onChange={(ev) =>
                                                    selectPermission(ev.target, false, "langs")
                                                }
                                            />
                                            {t("addUser.no")}
                                        </label>
                                    </div>

                                    <div className={s.access_point}>
                                        <p className={s.access_subtitle}>{t("addUser.sectionControl")}</p>
                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-pages"
                                                value="view"
                                                defaultChecked={user?.permissions?.pages}
                                                onChange={(ev) => {
                                                    selectPermission(ev.target, true, "pages");
                                                }}
                                            />
                                            {t("addUser.yes")}
                                        </label>

                                        <label className={s.label}>
                                            <input
                                                type="radio"
                                                name="permission-pages"
                                                value="view"
                                                defaultChecked={user ? !user?.permissions?.pages: false}
                                                onChange={(ev) => {
                                                    selectPermission(ev.target, false, "pages");
                                                }}
                                            />
                                            {t("addUser.no")}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={s.wrap_buttons}>
                                <SecondaryButton
                                    text={t("general.cancel")}
                                    type={"button"}
                                    onClick={() => close(false)}
                                />
                                <PrimaryButton text={t("general.confirm")} type={"submit"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}