import React from 'react';
import s from './Back.module.scss';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';

type Back = {
    close: any;
    text: string;
}

export const Back = ({ close, text }: Back) => {
    return (
        <div className={s.back} onClick={() => close(false)}>
            <ArrowBack />
            {text}
        </div>
    )
}